import React, { FC, useEffect, useRef, useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"

import { env } from "@/config"
import { generateFormQueryParams } from "@/services/links"
import { useGoogleMaps } from "@/app/hooks/use-google-maps"
import { EMAIL_STORAGE } from "@/constants/storage"
import { catBreeds, dogBreeds } from "@/constants/pet-breed/form-breeds"
import { PlaceInputPlaceholder } from "../location-placeholder"
import { PlaceInputAutocomplete } from "../location"
import type { GoogleAddressData } from "../location"
import { Email } from "../email"

const addressSchema = z.object({
  formatted_address: z.string(),
  city: z.string(),
  state_code: z.string(),
  lat: z.number(),
  lng: z.number(),
  zip: z.string().nullable(),
})

const transportationSchema = z.object({
  pickup: addressSchema.nullable(),
  delivery: addressSchema.nullable(),
  email: z.string().email().optional().or(z.literal("")),
  name: z.string().min(1, "Name is required"),
  breed: z.string().min(1, "Breed is required"),
  size: z.string().min(1, "size is required"),
  count: z.number().min(1, "At least one is required"),
  pickupDate: z.string().min(1, "Pickup date is required"),
  isOlderThan8Weeks: z.boolean(),
})

export type Props = {
  buttonLink?: string
  buttonLabel?: string
  animalType?: string
}

const CustomSelect = ({
  options,
  value,
  onChange,
  placeholder,
  name,
  testId,
}: any) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleSelect = (option: string) => {
    onChange(option)
    setIsOpen(false)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        name={name}
        type="button"
        data-testid={testId}
        onClick={() => setIsOpen(!isOpen)}
        className="w-full px-1 mt-4 focus:outline-none text-sm bg-gray50 h-[56px] border-b text-neutral-600 border-black"
      >
        {value || placeholder}
      </button>
      {isOpen && (
        <ul className="absolute z-10 w-full bg-white border rounded-md shadow-md p-4 mt-1 mx-0">
          {options.map((option: string) => (
            <li
              key={option}
              onClick={() => handleSelect(option)}
              className="px-4 py-2 cursor-pointer hover:bg-gray-100 text-sm -mx-4 border-b border-gray-200 last-of-type:border-none"
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export const PremiumHeroListingForm: FC<Props> = (props) => {
  const { buttonLabel, buttonLink, animalType } = props
  const APP_HOST = env("APP_HOST")
  const LINK = buttonLink ?? "/shipments/new"

  const [focusedField, setFocusedField] = useState<
    "pickup" | "delivery" | null
  >(null)

  const {
    handleSubmit,
    control,
    setFocus,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<{
    pickup: null | GoogleAddressData
    delivery: null | GoogleAddressData
    email: string
    name: string
    breed: string
    size: string
    count: number
    pickupDate: string
    isOlderThan8Weeks: boolean
  }>({
    defaultValues: {
      pickup: null,
      delivery: null,
      email: "",
      name: "",
      breed: "",
      size: "",
      count: 1,
      pickupDate: "",
      isOlderThan8Weeks: false,
    },
    resolver: zodResolver(transportationSchema),
  })

  const { mapsLoaded, loadMaps } = useGoogleMaps()

  useEffect(() => {
    if (mapsLoaded && focusedField != null) {
      setFocus(focusedField)
    }
  }, [mapsLoaded, focusedField, setFocus])

  const onSubmit = handleSubmit((data) => {
    let queryParams: Record<string, string | number> = {}

    if (data.pickup) {
      queryParams = {
        ...queryParams,
        pickupFA: data.pickup.formatted_address,
        pickupSC: data.pickup.state_code,
        pickupCt: data.pickup.city,
        pickupLat: data.pickup.lat,
        pickupLng: data.pickup.lng,
        pickupZip: data.pickup.zip ?? "",
      }
    }

    if (data.delivery) {
      queryParams = {
        ...queryParams,
        deliveryFA: data.delivery.formatted_address,
        deliverySC: data.delivery.state_code,
        deliveryCt: data.delivery.city,
        deliveryLat: data.delivery.lat,
        deliveryLng: data.delivery.lng,
        deliveryZip: data.delivery.zip ?? "",
      }
    }

    queryParams = {
      ...queryParams,
      email: data.email,
      name: data.name,
      breed: data.breed,
      size: data.size,
      count: data.count,
      pickupDate: data.pickupDate,
    }

    if (data.email) {
      sessionStorage.setItem(EMAIL_STORAGE, data.email)
    }

    const formQueryParams = generateFormQueryParams({ query: queryParams })
    window.location.assign(`${APP_HOST}${LINK}${formQueryParams}`)
  })

  const updateCount = (type: "increment" | "decrement") => {
    const currentValue = getValues("count")
    const newValue = Math.max(1, currentValue + (type === "increment" ? 1 : -1))
    setValue("count", newValue)
  }

  return (
    <form onSubmit={onSubmit} className="space-y-6">
      <div className="md:grid grid-cols-5 gap-6 md:gap-5">
        {/* Name */}
        <div className="col-span-1">
          <label className="flex uppercase text-gray16 text-base">
            {animalType}'s Name<span className="text-gray16">*</span>
          </label>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                className="w-full p-4 mt-4 focus:outline-none text-sm bg-gray50 h-[56] border-b  text-neutral-600 border-black"
                data-testid="pet-name"
              />
            )}
          />
          {errors.name && (
            <span className="text-red-500 text-sm">{errors.name.message}</span>
          )}
        </div>

        {/* Breed */}
        <div className="col-span-1 mt-4 md:mt-0">
          <label className="flex uppercase text-gray16 text-base">
            Breed<span className="text-gray16">*</span>
          </label>
          <Controller
            name="breed"
            control={control}
            render={({ field }) => {
              const breeds =
                animalType == "dog"
                  ? dogBreeds.map((item) => item.name)
                  : catBreeds

              return (
                <CustomSelect
                  options={breeds}
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Select Breed"
                  testId="pet-breed"
                />
              )
            }}
          />
          {errors.breed && (
            <span className="text-red-500 text-sm">{errors.breed.message}</span>
          )}
        </div>

        {/*  Size */}
        <div className="col-span-1  mt-4 md:mt-0">
          <label className="flex uppercase text-gray16 text-base">
            {animalType} Size<span className="text-gray16">*</span>
          </label>
          <Controller
            name="size"
            control={control}
            render={({ field }) => (
              <CustomSelect
                options={["Small", "Medium", "Large"]}
                value={field.value}
                onChange={field.onChange}
                placeholder="Select Size"
                testId="pet-size"
              />
            )}
          />
          {errors.size && (
            <span className="text-red-500 text-sm">{errors.size.message}</span>
          )}
        </div>

        {/* Number of Dogs */}
        <div className="col-span-1  mt-4 md:mt-0">
          <label className="flex uppercase text-gray16 text-base">
            <span className="hidden md:block lg:hidden">
              No. of {animalType}s
            </span>
            <span className="hidden lg:block">Number of {animalType}s</span>
            <span className="block md:hidden">
              How many {animalType}s are you transporting
            </span>
          </label>
          <div className="flex items-center p-0 w-full relative text-base bg-gray50 h-[57px] focus:outline-none text-neutral-600 border-black border-b mt-4">
            <Controller
              name="count"
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  type="number"
                  readOnly
                  className="w-full h-full text-center bg-transparent"
                  data-testid="pet-count"
                />
              )}
            />
            <button
              type="button"
              onClick={() => updateCount("decrement")}
              className="px-3 py-2 bg-gray-100 hover:bg-gray-200"
              data-testid="decrement-count"
            >
              -
            </button>
            <button
              type="button"
              onClick={() => updateCount("increment")}
              className="px-3 py-2 bg-gray-100 hover:bg-gray-200"
              data-testid="increment-count"
            >
              +
            </button>
          </div>
        </div>

        {/* Ideal Pickup Date */}
        <div className="col-span-1 mt-4 md:mt-0">
          <label className="flex uppercase text-gray16 text-base">
            <span className="hidden md:block lg:hidden">Pickup Date</span>
            <span className="hidden xl:block"> Ideal Pickup Date</span>
            <span className="block md:hidden">Ideal Pickup Date</span>
            <span className="text-gray16">*</span>
          </label>
          <Controller
            name="pickupDate"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="date"
                className="p-4 w-full relative text-sm bg-gray50 h-[57px] focus:outline-none text-neutral-600 border-black border-b mt-4"
              />
            )}
          />
          {errors.pickupDate && (
            <span className="text-red-500 text-sm">
              {errors.pickupDate.message}
            </span>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-4 sm:gap-x-4 md:gap-x-6 lg:gap-x-8">
        <div>
          <Controller
            name="pickup"
            control={control}
            render={({ field, fieldState }) => {
              const error = fieldState.error
                ? fieldState.error.message
                : undefined

              return mapsLoaded ? (
                <PlaceInputAutocomplete
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Pickup location"
                  type="pickup"
                  error={error}
                  id="pickup-location"
                  ref={field.ref}
                />
              ) : (
                <PlaceInputPlaceholder
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Pickup location"
                  type="pickup"
                  id="pickup-location"
                  onFocus={() => {
                    loadMaps()
                    setFocusedField("pickup")
                  }}
                />
              )
            }}
          />
        </div>
        <div>
          <Controller
            name="delivery"
            control={control}
            render={({ field, fieldState }) => {
              const error = fieldState.error
                ? fieldState.error.message
                : undefined

              return mapsLoaded ? (
                <PlaceInputAutocomplete
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Delivery location"
                  type="dropoff"
                  error={error}
                  id="dropoff-location"
                  ref={field.ref}
                />
              ) : (
                <PlaceInputPlaceholder
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Delivery location"
                  type="dropoff"
                  id="dropoff-location"
                  onFocus={() => {
                    loadMaps()
                    setFocusedField("delivery")
                  }}
                />
              )
            }}
          />
        </div>
        <div>
          <Controller
            control={control}
            name="email"
            render={({ field, fieldState }) => {
              const error = fieldState.error
                ? fieldState.error.message
                : undefined

              return (
                <div>
                  <Email
                    value={field.value}
                    ref={field.ref}
                    onChange={field.onChange}
                    label="Email"
                    error={error}
                    id="email"
                  />
                </div>
              )
            }}
          />
        </div>

        <div>
          <button
            className="w-full py-5 uppercase text-center bg-blue7 hover:bg-blue-700 mt-10 text-white text-md"
            onClick={onSubmit}
          >
            {buttonLabel}
          </button>
        </div>
      </div>
      <div className="mt-4">
        <label className="flex items-center ">
          <Controller
            name="isOlderThan8Weeks"
            control={control}
            render={({ field }) => (
              <input
                type="checkbox"
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                onBlur={field.onBlur}
                ref={field.ref}
                name={field.name}
                className="p-4 "
              />
            )}
          />
          <span className="text-sm text-gray-700 inline-block ml-2">
            Confirm pets are older than 8 weeks at the time of transport (USDA
            requirement)
          </span>
        </label>
        <span className="inline-block">
          {errors.pickupDate && (
            <span className="text-red-500 text-sm">
              {errors.pickupDate.message}
            </span>
          )}
        </span>
      </div>
    </form>
  )
}
